<template>
  <b-card class="m-1 col-md-5">
    <h5 class="mb-0">Tax Rate (%)</h5>
    <small class="text-muted mb-3 d-block">You can change the default tax rate applied to this invoice by selecting from the options below.</small>

    <v-select
      v-model="taxRateSelected"
      :options="taxRateList"
      class="bg-white"
      :clearable="false"
      @input="setTaxSelected"
      id="slidepanel-edit-quote-tabs-details-tax-rate-list"
      code="id"
      label="displayName"
    ></v-select>
  </b-card>
</template>

<script>
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import InvoiceService from "@/services/invoice";
  const invoiceService = new InvoiceService();
  const global = new GlobalServices();
  export default {
    name: "TaxRateSelection",
    props: {
      dataObj: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        taxRateSelected: null,
        taxRateList: [],
      };
    },
    methods: {
      setTaxSelected(evt) {
        this.updateField("IntTaxCodeID", evt.id, "Tax Code ID", evt.displayName, MODULE.INVOICE.ActionId);
        // setTimeout(() => {
        // this.updateField("TaxRate", evt.id, "Tax Rate", evt.displayName, 'TaxRate', null, null, null, null, MODULE.INVOICE.ActionId)
        // }, 500);
      },
      async init() {
        this.taxRateList = await global.getUXDropDown(MODULE.INVOICE.UX.InvoiceTaxRates);
        if (this.dataObj) {
          this.getPresetData();
        }
      },
      async getPresetData() {
        const details = await invoiceService.getInvoiceEditPreset(this.dataObj.id);
        if (details && details.taxCodeId) this.taxRateSelected = this.taxRateList.find((tax) => tax.id === details.taxCodeId);
      },
      async updateField(propertyName, propertyValue, displayName, displayValue = null, overrideActionId = null, immediate = false) {
        let editObj = {};
        editObj.propertyName = propertyName;
        editObj.propertyValue = propertyValue;
        editObj.displayName = displayName;
        editObj.displayValue = displayValue;
        editObj.id = this.dataObj.id;
        if (overrideActionId) editObj.actionId = overrideActionId;
        editObj.isUXProperty = true;
        let isValid = await this.isValidated(propertyName, propertyValue);

        if (isValid) {
          this.postAPIData(editObj, immediate);
        }
      },
      async isValidated(propertyName, propertyValue) {
        //Prevent empty value from being sent
        if (propertyValue == null || propertyValue.length == 0) return false;

        return true;
      },
      async postAPIData(dataObj, immediate = false) {
        if (immediate) {
          await global.postData(dataObj);
        } else {
          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }
          this.timer = setTimeout(() => {
            global.postData(dataObj);
          }, 500);
        }
      },
    },
    mounted() {
      this.init();
    },
  };
</script>
